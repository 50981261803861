<script setup lang="ts">
  import { onMounted } from 'vue';
  import { useFilter } from '#website/elements/filter';
  import { dialog } from '@/components/modal/modal';

  import Location from '#website/components/Location.vue';
  import Themes from '#website/components/themes/Themes.vue';
  import Dropdown from '@/components/dropdown/Dropdown.vue';

  const { filter, loading, locations, create, search, dropdown } = useFilter();

  const [FilterModal, openFilterModal] = dialog(() => import('../../elements/filter/FilterModal.vue'));

  onMounted(filter.search);
</script>

<template>
  <template v-if="loading">
    <div class="loader">
        Resulaten laden
        <div class="loader__background">
            <span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span>
        </div>
    </div>
  </template>
  <template v-else>
    <section class="section overflow">
      <div class="container">
        <div class="section__header">
          <h2 class="section__title">{{ locations?.meta.total ?? 0 }} locaties gevonden</h2>
          <div class="section__filters">
            <div class="section__filter">
              <label>Sorteer op</label>
              <Dropdown v-model:value="search.form.value.sort" :options="dropdown.sort" class="select" :searchable="false" placeholder="sorteer" />
            </div>
            <div class="section__filter">
              <a href="javascript:void(0)" class="button filters" @click="openFilterModal()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 13.5">
                  <path
                    fill="#202020"
                    d="M2.083 0c.886 0 1.643.553 1.944 1.333h8.723a.75.75 0 1 1 0 1.5l-8.723.001A2.084 2.084 0 1 1 2.083 0Zm0 1.5a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167ZM6.75 4.667c.886 0 1.643.553 1.944 1.333h4.056a.75.75 0 1 1 0 1.5H8.694a2.084 2.084 0 0 1-3.888 0H.75a.75.75 0 0 1 0-1.5h4.055A2.084 2.084 0 0 1 6.75 4.667Zm0 1.5a.583.583 0 1 0 0 1.166.583.583 0 0 0 0-1.166Zm4.667 3.166a2.083 2.083 0 1 1-1.944 2.834H.75a.75.75 0 1 1 0-1.5l8.723-.001a2.084 2.084 0 0 1 1.944-1.333Zm0 1.5a.583.583 0 1 0 0 1.167.583.583 0 0 0 0-1.167Z"
                  />
                </svg>
                Filters
              </a>
            </div>
            <FilterModal size="md" />
          </div>
        </div>
        <div class="grid--four grid">
          <template v-for="location in locations?.data.slice(0, 12)" :key="location.id">
            <Location :location="location"></Location>
          </template>
        </div>
      </div>
    </section>
    <Themes :themes="create?.themes.slice(0, 6)" />
    <section class="section overflow">
      <div class="container">
        <div class="grid--four grid">
          <template v-for="location in locations?.data?.slice(13, 21)" :key="location.id">
            <Location :location="location"></Location>
          </template>
        </div>
      </div>
    </section>
  </template>
</template>
