<script setup lang="ts">
  import Dropdown from '@/components/dropdown/Dropdown.vue';

  import type { DropDownValues } from '../../../types';

  const theme = defineModel<number>('theme');
  const search = defineModel<string | number>('search');

  defineProps<{ dropdown: DropDownValues['theme'] }>();
</script>

<template>
  <div class="block__item">
    <p class="block__label">Gelegenheid</p>
    <Dropdown v-model:value="theme" v-model:selected="search" :options="dropdown.value" placeholder="Kies een gelegenheid" input-class="block__title" :searchable="false" />
  </div>
</template>
