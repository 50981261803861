<script setup lang="ts">
  import { onMounted, ref } from 'vue';
  import { useAutocomplete } from '.';

  const query = defineModel<string | null>('query', { required: true });
  const search = defineModel<string>('search');

  const inputElement = ref<HTMLInputElement | null>(null);

  const { suggestions, activeSuggestionIndex, dropdownElementIsOpen, init, onInput, onArrowDown, onArrowUp, onEnter, onBlur, selectSuggestion } = useAutocomplete(inputElement, search, query);

  onMounted(() => {
    inputElement.value!.innerText = search.value || '';
  });
</script>

<template>
  <div class="block__item" :class="{ open: dropdownElementIsOpen }" v-outside="() => (dropdownElementIsOpen = false)" @click="inputElement?.focus()">
    <p class="block__label">Waar</p>
    <p ref="inputElement" class="block__title" contenteditable="true" tabindex="0" @focusin="init" @input="onInput" @blur="onBlur" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" v-bind="$attrs"></p>
    <div v-if="suggestions.length" class="block__dropdown list">
      <ul>
        <template v-for="(suggestion, index) in suggestions" :key="suggestion.place_id">
          <li :class="{ active: index === activeSuggestionIndex }" @mousedown="selectSuggestion(suggestion)">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="16" height="16">
              <path stroke="#202020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 13v0a3 3 0 0 1-3-3v0a3 3 0 0 1 3-3v0a3 3 0 0 1 3 3v0a3 3 0 0 1-3 3Z" clip-rule="evenodd" />
              <path stroke="#202020" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 21s-7-5.75-7-11a7 7 0 1 1 14 0c0 5.25-7 11-7 11Z" clip-rule="evenodd" />
            </svg>
            {{ suggestion.description }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped>
  [contenteditable='true'] {
    color: var(--Primary-500);
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  [contenteditable='true']:empty:before {
    color: var(--Grey_400, #ddd);
    content: attr(placeholder);
    pointer-events: none;
    display: block;
  }

  [contenteditable='true']:focus {
    outline: 0px solid transparent;
  }
</style>
