<script setup lang="ts">
  import '@vuepic/vue-datepicker/dist/main.css';
  import { ref } from 'vue';

  import VueDatePicker from '@vuepic/vue-datepicker';
  import Autocomplete from '@/apps/website/elements/filter/bar/desktop/components/Autocomplete/Location.vue';

  import MenuSlide from './components/MenuSlide.vue';
  import Select from '@/components/inputs/Select.vue';

  import type { UseForm } from '@/services/form';
  import type { DropDownValues, FiltersForm, QueryForm } from '../../types';

  const DEFAULT_PERSONS_VALUE = 0;

  const query = defineModel<UseForm<FiltersForm>>('query', { required: true });
  const search = defineModel<UseForm<QueryForm>>('search', { required: true });
  const dropdown = defineModel<DropDownValues>('dropdown', { required: true });

  defineEmits<{ (e: 'search'): void }>();

  const filterOpen = ref<boolean>(false);
</script>

<template>
  <template v-if="!filterOpen">
    <div class="block__item" @click="filterOpen = !filterOpen" v-outside="() => (filterOpen = false)">
      <p class="block__label">Waar</p>
      <p class="block__title">{{ search.form.value.location ?? 'Locatie' }} • {{ search.form.value.date ?? 'datum' }} • {{ search.form.value.persons ?? 'personen' }} • {{ search.form.value.theme ?? 'gelegenheid' }}</p>
      <a class="button icon" @click="filterOpen = !filterOpen">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
          <path
            fill="#FFF"
            d="M11.994 2.058a7.027 7.027 0 0 1 .503 9.378l2.783 2.784a.75.75 0 1 1-1.06 1.06l-2.784-2.783a7.027 7.027 0 1 1 .558-10.44ZM3.12 3.118a5.526 5.526 0 0 0 7.774 7.856.248.248 0 0 1 .037-.044l.042-.038.136-.141a5.526 5.526 0 0 0-7.99-7.632Z"
          />
        </svg>
      </a>
    </div>
  </template>
  <template v-else>
    <MenuSlide header="Vaar zoek je een zaaltje">
      <template #toggle>
        <div class="block__item">
          <p class="block__label">Waar</p>
          <p class="block__title">{{ search.form.value.location ?? 'Zoek een locatie' }}</p>
        </div>
      </template>
      <template #content>
        <Autocomplete v-model:query="query.form.value.zip_code" v-model:search="search.form.value.location" placeholder="Zoek een locatie" />
      </template>
    </MenuSlide>
    <MenuSlide header="Selecteer de gewensde datum">
      <template #toggle>
        <div class="block__item">
          <p class="block__label">Wanneer</p>
          <p class="block__title">{{ search.form.value.date ?? 'Selecteer een datum' }}</p>
        </div>
      </template>
      <template #content>
        <VueDatePicker v-model="search.form.value.date" placeholder="Selecteer een datum" locale="nl-NL" timezone="Europe/Amsterdam" format="yyyy-mm-dd" auto-apply inline />
      </template>
    </MenuSlide>
    <MenuSlide header="Met hoeveel personen ben je">
      <template #toggle>
        <div class="block__item">
          <p class="block__label">Aantal personen</p>
          <p class="block__title">{{ search.form.value.persons ?? 'Aantal personen' }}</p>
        </div>
      </template>
      <template #content>
        <div class="block__item persons">
          <p class="block__text">Aantal personen</p>
          <div class="quantity">
            <span @click.prevent="search.form.value.persons > DEFAULT_PERSONS_VALUE ? search.form.value.persons-- : null">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path fill="none" stroke="#B3B3B3" stroke-width="1.5" d="M16 .75C24.422.75 31.25 7.578 31.25 16S24.422 31.25 16 31.25.75 24.422.75 16 7.578.75 16 .75ZM11 16h10" />
              </svg>
            </span>
            <input class="persons-input" name="persons-input" type="number" value="0" min="1" v-model="search.form.value.persons" />
            <span @click.prevent="search.form.value.persons++">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <path
                  fill="#662056"
                  d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0Zm0 1.5C7.992 1.5 1.5 7.992 1.5 16S7.992 30.5 16 30.5 30.5 24.008 30.5 16 24.008 1.5 16 1.5Zm0 8.75a.75.75 0 0 1 .75.75v4.25H21a.75.75 0 1 1 0 1.5h-4.25V21a.75.75 0 1 1-1.5 0v-4.25H11a.75.75 0 1 1 0-1.5h4.25V11a.75.75 0 0 1 .75-.75Z"
                />
              </svg>
            </span>
          </div>
        </div>
      </template>
    </MenuSlide>
    <MenuSlide header="Kies een gelegenheid">
      <template #toggle>
        <div class="block__item">
          <p class="block__label">Gelegenheid</p>
          <p class="block__title">{{ search.form.value.theme ?? 'Kies een gelegenheid' }}</p>
        </div>
      </template>
      <template #content>
        <Select type="text" v-model:value="query" name="theme" :options="dropdown.theme?.value" placeholder="Kies een gelegenheid" />
      </template>
    </MenuSlide>
    <div class="block__item">
      <button class="button" @click="$emit('search')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
          <path
            fill="#FFF"
            d="M11.994 2.058a7.027 7.027 0 0 1 .503 9.378l2.783 2.784a.75.75 0 1 1-1.06 1.06l-2.784-2.783a7.027 7.027 0 1 1 .558-10.44ZM3.12 3.118a5.526 5.526 0 0 0 7.774 7.856.248.248 0 0 1 .037-.044l.042-.038.136-.141a5.526 5.526 0 0 0-7.99-7.632Z"
          />
        </svg>
        Zoek een zaaltje
      </button>
    </div>
  </template>
</template>
