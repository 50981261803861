<script setup lang="ts">
  import { ref } from 'vue';

  defineProps<{
    header: string;
  }>();

  const open = ref<boolean>(false);

  function toggle() {
    open.value = !open.value;
  }
</script>

<template>
  <div class="block" v-outside="() => (open = false)" @click.prevent="toggle">
    <slot name="toggle" v-bind="{ open, toggle }"></slot>
    <div @click.stop class="block__overlay" :class="{ open: open }">
      <div class="block__overlay__wrapper">
        <div class="block__overlay__head">
          <p>{{ header }}</p>
          <button @click="toggle">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.5 9.5">
              <path fill="#8F116F" d="m1.28.22 3.47 3.469L8.22.219A.75.75 0 0 1 9.28 1.28L5.811 4.75l3.47 3.47A.75.75 0 0 1 8.22 9.28L4.75 5.811l-3.47 3.47A.75.75 0 0 1 .22 8.22l3.469-3.47-3.47-3.47A.75.75 0 0 1 1.28.22Z"></path>
            </svg>
          </button>
        </div>
        <slot name="content" v-bind="{ open, toggle }"></slot>
        <div class="block__overlay__foot">
          <button class="button" @click="toggle">Klaar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .block {
    &__overlay {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      background: white;
      z-index: 999;
      display: none;
      border-radius: 32px 32px 0 0;
      box-shadow: 0px -8px 16px 0px #0000000d;

      &::before {
        box-shadow: 0px -8px 16px 0px #0000000d;
      }

      &.open {
        display: block;
      }

      &__wrapper {
        padding: 24px;
      }

      &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 16px 0;

        p {
          flex-grow: 1;
          text-align: center;
          margin: 0;
        }

        button {
          border: none;
          background-color: transparent;

          svg {
            width: 10px;
            height: 10px;
          }
        }
      }

      &__foot {
        display: flex;
        justify-content: center;
        margin: 16px 0 0 0;

        button {
          width: 100%;
        }
      }
    }
  }
</style>
