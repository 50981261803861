import { i18n } from '@/services/i18n';
import { paramsObject } from '@/services/search';
import { onMounted, ref, watch, type ModelRef, type Ref } from 'vue';

let googleInit: google.maps.places.AutocompleteService;

async function init() {
  await google.maps.importLibrary('places');
  googleInit = new google.maps.places.AutocompleteService();
}

export function useAutocomplete(element: Ref<HTMLInputElement | null> | null, search: ModelRef<string | undefined> | Ref<string | undefined>, query: ModelRef<string | null> | Ref<string | null>) {
  const dropdownElementIsOpen = ref<boolean>(false);
  const activeSuggestionIndex = ref<number>(-1);
  const suggestions = ref<google.maps.places.AutocompletePrediction[]>([]);

  const pattern = /^\d{0,4}[a-zA-Z]{0,2}$/.test(search.value!) ? 'postal_code' : 'geocode';

  async function fetchSuggestions(pattern: string, input: string) {
    await googleInit?.getPlacePredictions(
      {
        input,
        types: [pattern], 
        componentRestrictions: { country: i18n.global.locale.value }, 
        language: i18n.global.locale.value,
      },
      (predictions, status) => {
        if (!predictions && pattern === 'postal_code') {
          fetchSuggestions('geocode', input);
          return;
        }

        if (status === google.maps.places.PlacesServiceStatus.OK) {
          suggestions.value = predictions || [];
        } else {
          suggestions.value = [];
        }
      },
    );
  }

  function getPostalCodeByPlaceId(placeId: string): Promise<string | null> {
    const service = new google.maps.places.PlacesService(document.createElement('div'));

    return new Promise((resolve, reject) => {
      service.getDetails(
        {
          placeId: placeId,
          fields: ['address_component', 'geometry', 'place_id', 'name'],
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            const postalCode = place?.address_components?.find((component) => component.types.includes('postal_code'))?.long_name;

            if (postalCode) {
              query.value = postalCode;
              resolve(postalCode); 
            } else {
              resolve(null); 
            }
          } else {
            console.log('Place details request failed:', status);
            reject(status); 
          }
        },
      );
    });
  }

  function selectSuggestion(suggestion: google.maps.places.AutocompletePrediction) {
    if (!element?.value) return;

    search.value = suggestion.description || '';

    getPostalCodeByPlaceId(suggestion.place_id);

    if (element.value) {
      element.value.innerHTML = suggestion.description; // Or use textContent
    }

    dropdownElementIsOpen.value = false;
  }

  function onInput(event: Event) {
    search.value = (event.target as HTMLInputElement).innerHTML;

    if (search.value) {
      dropdownElementIsOpen.value = true;
      fetchSuggestions(pattern, search.value);
    } else {
      dropdownElementIsOpen.value = false;
      suggestions.value = [];
    }
  }

  const onArrowDown = () => {
    if (activeSuggestionIndex.value < suggestions.value.length - 1) {
      activeSuggestionIndex.value++;
    } else {
      activeSuggestionIndex.value = 0; // Loop back to the top
    }
  };

  const onArrowUp = () => {
    if (activeSuggestionIndex.value > 0) {
      activeSuggestionIndex.value--;
    } else {
      activeSuggestionIndex.value = suggestions.value.length - 1; // Loop back to the bottom
    }
  };

  const onEnter = (event: KeyboardEvent) => {
    event.preventDefault();
    if (activeSuggestionIndex.value !== -1) {
      selectSuggestion(suggestions.value[activeSuggestionIndex.value]);
    }
  };

  function onBlur() {
    setTimeout(() => (dropdownElementIsOpen.value = false), 100);
  }

  onMounted(() => {
    if (!element?.value) return;
    element.value.innerHTML = paramsObject.value?.location || '';
  });

  watch(search, () => {
    if (search.value?.length === 0) {
      query.value = null;
    }
  });

  return {
    dropdownElementIsOpen,
    activeSuggestionIndex,
    suggestions,
    pattern,
    query,
    selectSuggestion,
    fetchSuggestions,
    getPostalCodeByPlaceId,
    init,
    onInput,
    onArrowDown,
    onArrowUp,
    onEnter,
    onBlur,
  };
}
