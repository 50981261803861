<script setup lang="ts">
  import { ref } from 'vue';
  import { api } from '@/services/api';

  import { addGoogleRecaptchaToHEAD } from '.';

  addGoogleRecaptchaToHEAD();

  const RECAPTCHA_SITEKEY = import.meta.env.VITE_RECAPTCHA_SITEKEY;

  const props = defineProps<{ callback: any }>();

  const buttonElement = ref<HTMLButtonElement | null>(null);
  const message = ref<string>();

  async function greCaptcha(value: string) {
    message.value = '';

    try {
      const { data } = await api.post<{ message: string; verified: boolean }>('api/captcha', { 'g-recaptcha-response': value });
      if (!data.verified) {
        message.value = data.message;
        return;
      }
      if (props.callback) props.callback();
      buttonElement.value?.closest('form')?.submit();
    } catch (error) {
      console.warn(error);
    }
  }

  window.greCaptcha = greCaptcha;
</script>

<template>
  <span>{{ message }}</span>
  <button type="submit" v-bind="$attrs" class="button g-recaptcha" ref="buttonElement" :data-sitekey="RECAPTCHA_SITEKEY" data-action="submit" data-callback="greCaptcha" data-size="invisible">
    <slot />
  </button>
</template>

<style>
  .grecaptcha-badge {
    visibility: hidden !important;
  }
</style>
