import { createApp } from 'vue';

import { i18n } from '@/services/i18n';

import { outside } from '@/services/outside';
import { loadGoogleAPI } from '@/services/google';
import { addGoogleRecaptchaToHEAD } from '@/components/google/captcha';

import { closeGalleryModal, closeMapsModal, closeSpaceModal, GalleryModal, mainMenuIsOpen, MapsModal, openGalleryModal, openMapsModal, openSelectedSpaceModal, selectedSpace, SpaceModal } from './pages/location/show';

// elements
import FiltersBar from '#website/elements/filter/bar/Wrapper.vue';
import LocationIndex from '#website/pages/location/Index.vue';

import AddFavorite from '@/apps/website/elements/favorite/AddFavorite.vue';
import GetFavorite from '@/apps/website/elements/favorite/GetFavorite.vue';

import AddCompare from '#website/elements/compare/AddCompare.vue';
import GetCompare from '#website/elements/compare/GetCompare.vue';

// pages
import ReserveLocation from '#website/pages/reserve/Wrapper.vue';
import RegisterLocation from '#website/pages/register/Wrapper.vue';
import FaqIndex from '#website/pages/faq/Index.vue';
import SupplierIndex from '#website/pages/supplier/Index.vue';

// show page
import BookLocation from '#website/elements/BookLocation.vue';

import ShareArticle from '@/apps/website/pages/location/show/components/ShareArticle.vue';
import Themes from '#website/components/themes/Themes.vue';
import ThemeIcons from '#website/components/themes/ThemeIcons.vue';

import CaptchaButton from '@/components/google/captcha/Button.vue';

const { locale } = i18n.global;

loadGoogleAPI({
  key: import.meta.env.VITE_GOOGLE_API_KEY,
  v: 'weekly',
  language: locale.value,
  libraries: 'places,geometry,drawing',
});

import.meta.glob(['../../assets/images/**']);

addGoogleRecaptchaToHEAD();

const app = createApp({
  components: {
    LocationIndex,
    FiltersBar,

    AddFavorite,
    GetFavorite,

    AddCompare,
    GetCompare,

    BookLocation,

    RegisterLocation,
    ReserveLocation,
    FaqIndex,
    SupplierIndex,

    ThemeIcons,
    Themes,

    ShareArticle,

    SpaceModal,
    GalleryModal,
    MapsModal,

    CaptchaButton,
  },

  setup() {
    return {
      mainMenuIsOpen,
      selectedSpace,

      openSelectedSpaceModal,
      closeSpaceModal,

      openGalleryModal,
      closeGalleryModal,

      openMapsModal,
      closeMapsModal,
    };
  },
});

app.use(i18n);
app.use(outside);

app.mount('#website');
