import { defineAsyncComponent, ref, shallowRef } from 'vue';

import { api } from '@/services/api';
import { useForm } from '@/services/form';
import { storage } from '@/services/storage';
import { i18n } from '@/services/i18n';
import { AxiosError } from 'axios';

import type { LocationCreate, RegisterLocation, RegisterLocationMedia, RegisterStep } from './types';
import type { Location } from '@/types/location';

const { t } = i18n.global;

export const CURRENTLY_STORAGE_STEP = '__diezv_register_step';
export const CURRENTLY_STORAGE_DATA = '__diezv_register_data';
export const CURRENTLY_SAVED_DATA = '__diezv_saved_data';

export const loading = ref<boolean>(false);

export const currentStep = ref<number>(storage.get<number>(CURRENTLY_STORAGE_STEP) ?? 0);
export const createdLoction = ref<Location | null>(storage.get<Location>(CURRENTLY_SAVED_DATA) ?? null);
export const locationCreate = ref<LocationCreate>();

export const location = {
  create: async () => {
    try {
      const { data } = await api.get<LocationCreate>('api/create');
      return data;
    } catch (error) {
      console.log(error);
    }
  },
  async store() {
    loading.value = true;
    values.clearErrors();

    try {
      // Get all formdata, but map facilities

      const formData = {
        ...values.form.value,
        facilities: values.form.value.facilities.map((facility) => facility.id),
      };

      const { data } = await api.post<Location>('api/location/register', formData);
      if (!data) return;

      if (mediaForm.form.value.images.length > 0) {
        const uploadPromises = mediaForm.form.value.images.map((image) => {
          if (!image.image) return Promise.resolve();

          return api.post(
            `api/location/register/${data.id}/media`,
            {
              image: image.image,
              order_column: image.order_column,
            },
            { headers: { 'Content-Type': 'multipart/form-data' } },
          );
        });

        await Promise.all(uploadPromises);
      }

      createdLoction.value = data;
      storage.set(CURRENTLY_SAVED_DATA, data);

      currentStep.value++;
    } catch (error) {
      const ruleNumber = getRuleNumber(error);
      if (ruleNumber !== null) {
        currentStep.value = ruleNumber;
      }
    } finally {
      loading.value = false;
    }
  },
  async update(id?: number) {
    if (!id) return;

    values.clearErrors();

    try {
      const { data } = await api.patch<Location>(`api/location/register/${id}`, supplierForm.form.value);
      if (!data) return;

      createdLoction.value = data;

      storage.remove(CURRENTLY_STORAGE_STEP);
      storage.remove(CURRENTLY_STORAGE_DATA);
      storage.remove(CURRENTLY_SAVED_DATA);

      window.history.go(-1);
      return false;
    } catch (error) {
      const ruleNumber = getRuleNumber(error);
      if (ruleNumber !== null) {
        currentStep.value = ruleNumber;
      }
    }
  },
};

const rules = shallowRef<Record<number, string[]>>({
  1: ['name', 'zip_code', 'city', 'street', 'house_number', 'house_letter', 'house_number_addition'],
  2: ['facilities.*', 'images.*.image', 'images.*.order_column'],
  3: ['opening_times.*.day', 'opening_times.*.open_from', 'opening_times.*.open_to'],
  4: ['spaces.*.name', 'spaces.*.persons_min', 'spaces.*.persons_max'],
  5: ['personal.*.first_name', 'personal.*.last_name', 'personal.*.email', 'personal.*.phone', 'personal.*.company_name', 'personal.*.signup_notes'],
  6: ['payment.subscription'],
  7: ['payment.name', 'payment.iban', 'payment.btw', 'payment.autopay'],
});

function getRuleNumber(errors?: unknown) {
  if (!errors) return null;

  if (errors instanceof AxiosError) {
    values.setErrors(errors);
    for (const errorKey in errors.response?.data.errors) {
      for (const ruleNumber in rules.value) {
        for (const rulePattern of rules.value[ruleNumber]) {
          const regexPattern = new RegExp('^' + rulePattern.replace(/\./g, '\\.').replace(/\*/g, '\\d+') + '$');

          if (regexPattern.test(errorKey)) {
            return Number(ruleNumber);
          }
        }
      }
    }
  }

  return null;
}

export function goNext() {
  currentStep.value++;
  storage.set(CURRENTLY_STORAGE_STEP, currentStep.value);
}

export function goBack() {
  if (currentStep.value === 0) return;
  currentStep.value--;
  storage.set(CURRENTLY_STORAGE_STEP, currentStep.value);
}

export const values = useForm<RegisterLocation>({
  name: '',
  description: '',
  address: {
    zip_code: '',
    city: '',
    street: '',
    house_number: '',
    house_letter: '',
    house_number_addition: undefined,
  },
  facilities: [],
  extra_options: [],
  opening_times: [],
  spaces: [
    {
      name: '',
      persons_min: 0,
      persons_max: 0,
    },
  ],
  personal: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    company_name: '',
    signup_notes: '',
  },
  payment: {
    name: '',
    iban: '',
    btw: '',
    autopay: true,
    plan: locationCreate.value?.plans.find((plan) => plan.default === true)?.id ?? 1,
  },
});

export const mediaForm = useForm<RegisterLocationMedia>({
  images: [],
});

export const supplierForm = useForm({
  suppliers: [],
});

export const steps = shallowRef<RegisterStep[]>([
  {
    step: 0,
    previous: false,
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/0-Intro.vue')),
    button: t('steps.0.button'),
  },
  {
    step: 1,
    title: t('steps.1.title'),
    description: t('steps.1.description'),
    footer: t('steps.1.footer'),
    button: t('steps.1.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/1-General.vue')),
  },
  {
    step: 2,
    title: t('steps.2.title'),
    description: t('steps.2.description'),
    footer: t('steps.2.footer'),
    button: t('steps.2.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/2-ImagesCharacteristics.vue')),
  },
  {
    step: 3,
    title: t('steps.3.title'),
    description: t('steps.3.description'),
    footer: t('steps.3.footer'),
    button: t('steps.3.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/3-OpeningHours.vue')),
  },
  {
    step: 4,
    title: t('steps.4.title'),
    description: t('steps.4.description'),
    footer: t('steps.4.footer'),
    button: t('steps.4.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/4-Spaces.vue')),
  },
  // {
  //   step: 5,
  //   title: t('steps.5.title'),
  //   description: t('steps.5.description'),
  //   footer: t('steps.5.footer'),
  //   button: t('steps.5.button'),
  //   component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/5-Products.vue')),
  // },
  {
    step: 5,
    title: t('steps.6.title'),
    description: t('steps.6.description'),
    footer: t('steps.6.footer'),
    button: t('steps.6.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/6-ContactInfo.vue')),
  },
  {
    step: 6,
    title: t('steps.7.title'),
    description: t('steps.7.description'),
    footer: t('steps.7.footer'),
    button: t('steps.7.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/7-Plan.vue')),
  },
  {
    step: 7,
    title: t('steps.8.title'),
    description: t('steps.8.description', { link: "<a href='mailto:abbonement@daariseenzaaltjevoor.nl'> abbonement@daariseenzaaltjevoor.nl </a>." }),
    footer: t('steps.8.footer'),
    button: t('steps.8.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/8-Payment.vue')),
    submit: location.store,
  },
  {
    step: 8,
    title: t('steps.9.title'),
    previous: false,
    description: t('steps.9.description'),
    footer: t('steps.9.footer'),
    button: t('steps.9.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/9-Success.vue')),
  },
  {
    step: 9,
    title: t('steps.10.title'),
    description: t('steps.10.description'),
    footer: t('steps.10.footer'),
    button: t('steps.10.button'),
    component: defineAsyncComponent(() => import('@/apps/website/pages/register/steps/10-ExtraOptions.vue')),
    submit: location.update,
  },
]);

// FILLED OUT FORM FOR TESTING PURPOSES
// export const values = useForm<RegisterLocation>({
//   name: 'test',
//   description: 'test',
//   address: {
//     zip_code: '9711TD',
//     city: 'groningen',
//     street: 'kinkerstraat',
//     house_number: '5',
//     house_letter: 'A',
//     house_number_addition: undefined,
//   },
//   facilities: [1],
//   extra_options: [1],
//   opening_times: [{
//     day: 0,
//     open_from: '10:00',
//     open_to: '18:00',
//   }],
//   spaces: [
//     {
//       name: 'test',
//       persons_min: 1,
//       persons_max: 21,
//     },
//   ],
//   personal: {
//     first_name: 'test',
//     last_name: 'test',
//     email: 'test@test.nl',
//     phone: '0612345678',
//     company_name: 'test',
//     signup_notes: 'test',
//   },
//   payment: {
//     name: 'test',
//     iban: 'NL91INGB6971032004',
//     btw: 'test',
//     autopay: true,
//     subscription: 1,
//   }
// });

// onMounted(() => {
//   values.form.value.personal.email = 'wW3iZ@example.com' + Math.floor(Math.random() * 1000);
// });
