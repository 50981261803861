<script setup lang="ts">
  import { computed, shallowRef } from 'vue';

  import type { Article } from '@/types/article';

  const props = defineProps<{ article: Article }>();

  function createShareUrl(base: string, options?: Record<string, string>) {
    let url = new URL(base);

    if (options) {
      for (const [option, value] of Object.entries(options)) {
        url.searchParams.set(option, encodeURIComponent(value));
      }
    }

    return url.toString();
  }

  const shareUrlMap = shallowRef<Record<string, Function>>({
    twitter: () =>
      share(
        'twitter',
        createShareUrl('https://twitter.com/intent/tweet', {
          url: window.location.href,
        }),
      ),
    facebook: () =>
      share(
        'facebook',
        createShareUrl('https://www.facebook.com/sharer/sharer.php', {
          u: window.location.href,
        }),
      ),
    whatsapp: () =>
      share(
        'whatsapp',
        createShareUrl('https://web.whatsapp.com://send', {
          text: window.location.href,
        }),
      ),
    mail: () => (window.location.href = createShareUrl('mailto:', { subject: props.article.title, body: props.article.excerpt })),
    copy: () => navigator.clipboard.writeText(window.location.href),
  });

  const isMobile = computed(() => /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

  async function share(type: string, url: string) {
    if (navigator.share && !isMobile.value) {
      try {
        await navigator.share({
          title: props.article.title,
          text: props.article.excerpt,
          url: url,
        });
      } catch (error) {
        console.error('Error sharing the article:', error);
      }
    } else {
      console.log('navigator.share is not supported');
      window.open(url, '_blank');
    }
  }
</script>

<template>
  <div class="block--share block">
    <p class="block__text">Deel dit artikel</p>
    <ul>
      <li>
        <a @click.prevent="shareUrlMap['whatsapp']">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.535 20.535">
            <path
              fill="#8F116F"
              d="M10.816.015a10.267 10.267 0 1 1-5.811 19.069l-.043-.026-2.418.404c-.15.025-.304.023-.453-.006l-.148-.038a1.279 1.279 0 0 1-.87-1.427l.404-2.42-.026-.04A10.268 10.268 0 0 1 .082 8.97l.05-.342A10.268 10.268 0 0 1 10.816.015ZM4.764 3.442a8.768 8.768 0 0 0-1.87 11.57c.101.157.14.345.11.53l-.401 2.389 2.391-.4a.75.75 0 0 1 .432.057l.097.053a8.768 8.768 0 1 0-.759-14.199Zm2.862 1.096a1.75 1.75 0 0 1 1.277.654l1.276 1.595a1.75 1.75 0 0 1 .134 1.994l-.248.411.093.127c.306.397.661.753 1.058 1.058l.125.092.414-.247a1.75 1.75 0 0 1 .84-.248l.177.002c.357.024.697.157.976.38l1.595 1.276a1.748 1.748 0 0 1 .134 2.615l-.72.708a3.75 3.75 0 0 1-4.644.496 16.963 16.963 0 0 1-5.033-5.035 3.751 3.751 0 0 1 .5-4.637l.709-.72a1.75 1.75 0 0 1 1.337-.521ZM7.55 6.036a.25.25 0 0 0-.192.074l-.71.722a2.251 2.251 0 0 0-.304 2.776 15.446 15.446 0 0 0 4.577 4.579 2.251 2.251 0 0 0 2.783-.301l.721-.709a.25.25 0 0 0-.019-.374l-1.595-1.276a.25.25 0 0 0-.285-.019l-.822.493a.75.75 0 0 1-.779-.004A7.306 7.306 0 0 1 8.54 9.61a.75.75 0 0 1-.005-.78l.493-.821a.25.25 0 0 0-.019-.285L7.732 6.13a.25.25 0 0 0-.182-.093Z"
            />
          </svg>
        </a>
      </li>
      <li>
        <a @click.prevent="shareUrlMap['twitter']">
          <svg width="1200" height="1227" viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
              fill="#8F116F"
            />
          </svg>
        </a>
      </li>
      <li>
        <a @click.prevent="shareUrlMap['facebook']">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#8F116F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
          </svg>
        </a>
      </li>
      <li>
        <a @click.prevent="shareUrlMap['mail']">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 16.5">
            <path
              fill="#8F116F"
              d="M14.75 0a4.75 4.75 0 0 1 4.75 4.75v7a4.75 4.75 0 0 1-4.75 4.75h-10A4.75 4.75 0 0 1 0 11.75v-7A4.75 4.75 0 0 1 4.75 0h10Zm0 1.5h-10A3.25 3.25 0 0 0 1.5 4.75v7A3.25 3.25 0 0 0 4.75 15h10A3.25 3.25 0 0 0 18 11.75v-7a3.25 3.25 0 0 0-3.25-3.25Zm-9.7 3.046L9.254 6.38a1.25 1.25 0 0 0 .996.002l4.202-1.817a.75.75 0 0 1 .596 1.377l-4.203 1.817a2.75 2.75 0 0 1-2.19-.004L4.45 5.921a.75.75 0 1 1 .6-1.375Z"
            />
          </svg>
        </a>
      </li>
      <li>
        <a @click.prevent="shareUrlMap['copy']">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 10">
            <path
              fill="#8F116F"
              d="M7.361 0a.75.75 0 0 1 0 1.5H5a3.5 3.5 0 0 0 0 7h2.361a.75.75 0 0 1 0 1.5H5A5 5 0 0 1 5 0h2.361ZM14.5 0a5 5 0 0 1 0 10h-2.361a.75.75 0 1 1 0-1.5H14.5a3.5 3.5 0 0 0 0-7h-2.361a.75.75 0 1 1 0-1.5H14.5Zm-.75 4.5a.75.75 0 1 1 0 1.5h-8a.75.75 0 0 1 0-1.5h8Z"
            />
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>
