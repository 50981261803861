<script setup lang="ts">
  import type { Location } from '@/types/location';

  const values = defineModel<Location[]>('values', { required: true });
</script>

<template>
  <ul class="block__list">
    <li>
      <span>Faciliteiten</span>
      <template v-for="(item, index) in values" :key="index">
        <span>{{ item.facilities.length > 0 ? item.facilities?.join(', ') : '-' }}</span>
      </template>
    </li>
  </ul>
  <ul class="block__list">
    <li>
      <span>Ruimte</span>
      <template v-for="(item, index) in values" :key="index">
        <span>{{ item.spaces.length }}</span>
      </template>
    </li>
  </ul>
</template>
